//
//
//
//
//
//
//
//

import {Column, Plot,} from '@antv/g2plot'
import ChartCard from './card'
import mixins from './mixins'
export default {
  name: "ChartColumn",
  components: {
    ChartCard,
  },
  props: {
    color: {
      type: String,
    },
    label: {
      type: Object,
      required: true,
    },
    xAxis: {
      type: Object,
      required: true,
    },
    meta: {
      type: Object,
      required: true,
    },
  },
  mixins: [mixins],
  methods: {
    renderChart() {
      if(this.chartRef instanceof Plot) {
        this.chartRef.changeData(this.data)
      } else {
        this.chartRef = new Column(this.$refs.root, {
          data: this.data,
          xField: this.xField,
          yField: this.yField,
          color: this.color,
          label: this.label,
          xAxis: this.xAxis,
          meta: this.meta,
        })
        /*https://g2plot.antv.vision/zh/docs/api/options/events/#gatsby-focus-wrapper*/
        this.chartRef.on('element:click', e => void this.$emit('elementClick', e))
        this.chartRef.render()
      }
    },
  },
}
